import { default as indexntzJNtxNjbMeta } from "/app/pages/(auth)/account-confirm/index.vue?macro=true";
import { default as indexMivftKvBaGMeta } from "/app/pages/(auth)/login/index.vue?macro=true";
import { default as indexcCxEaBPsUFMeta } from "/app/pages/(auth)/register/index.vue?macro=true";
import { default as index7HromrWD0gMeta } from "/app/pages/(auth)/reset-password/index.vue?macro=true";
import { default as supportxBVZzJdsvgMeta } from "/app/pages/(content)/support.vue?macro=true";
import { default as convert4jgX4a8stLMeta } from "/app/pages/(dev)/convert.vue?macro=true";
import { default as playgroundJJ5fXSBmGNMeta } from "/app/pages/(dev)/playground.vue?macro=true";
import { default as typographyLmsZcHpd1AMeta } from "/app/pages/(dev)/typography.vue?macro=true";
import { default as privacy_45policyIDtNnRCO1lMeta } from "/app/pages/(legal)/privacy-policy.vue?macro=true";
import { default as terms_45and_45conditionsmBf2B2iEJHMeta } from "/app/pages/(legal)/terms-and-conditions.vue?macro=true";
import { default as indexNqIgPxbtepMeta } from "/app/pages/(products)/categories/[categorySlug]/[productSlug]/index.vue?macro=true";
import { default as index0coWt94GbEMeta } from "/app/pages/(products)/categories/[categorySlug]/index.vue?macro=true";
import { default as index2Mqezjia3yMeta } from "/app/pages/(products)/categories/index.vue?macro=true";
import { default as index8XAcpH4f5IMeta } from "/app/pages/(products)/products/[slug]/index.vue?macro=true";
import { default as aboutu8Sb32ihwPMeta } from "/app/pages/about.vue?macro=true";
import { default as indexAacQV8oPDMMeta } from "/app/pages/cart/index.vue?macro=true";
import { default as index7ZtduOIoVXMeta } from "/app/pages/checkout/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as labwLMn8naZaGMeta } from "/app/pages/lab.vue?macro=true";
import { default as indexBpuRKxqKBqMeta } from "/app/pages/orders/[id]/index.vue?macro=true";
import { default as addressesp9PeR2maRxMeta } from "/app/pages/profile/addresses.vue?macro=true";
import { default as gift_45cardscvz8Q3haqsMeta } from "/app/pages/profile/gift-cards.vue?macro=true";
import { default as ordersTvQzfKkQSJMeta } from "/app/pages/profile/orders.vue?macro=true";
import { default as profileupBlgdy3vLMeta } from "/app/pages/profile.vue?macro=true";
import { default as indexMyUrAiTuIVMeta } from "/app/pages/protein-articles/[slug]/index.vue?macro=true";
import { default as indexmCotOMunWZMeta } from "/app/pages/protein-articles/index.vue?macro=true";
import { default as servicesF19YTkmrIFMeta } from "/app/pages/services.vue?macro=true";
import { default as component_45stubnO9MU04yTUMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnO9MU04yTU } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "account-confirm___en",
    path: "/account-confirm",
    component: () => import("/app/pages/(auth)/account-confirm/index.vue")
  },
  {
    name: "account-confirm___pl",
    path: "/pl/account-confirm",
    component: () => import("/app/pages/(auth)/account-confirm/index.vue")
  },
  {
    name: "login___en",
    path: "/login",
    component: () => import("/app/pages/(auth)/login/index.vue")
  },
  {
    name: "login___pl",
    path: "/pl/login",
    component: () => import("/app/pages/(auth)/login/index.vue")
  },
  {
    name: "register___en",
    path: "/register",
    component: () => import("/app/pages/(auth)/register/index.vue")
  },
  {
    name: "register___pl",
    path: "/pl/register",
    component: () => import("/app/pages/(auth)/register/index.vue")
  },
  {
    name: "reset-password___en",
    path: "/reset-password",
    component: () => import("/app/pages/(auth)/reset-password/index.vue")
  },
  {
    name: "reset-password___pl",
    path: "/pl/reset-password",
    component: () => import("/app/pages/(auth)/reset-password/index.vue")
  },
  {
    name: "support___en",
    path: "/support",
    component: () => import("/app/pages/(content)/support.vue")
  },
  {
    name: "support___pl",
    path: "/pl/support",
    component: () => import("/app/pages/(content)/support.vue")
  },
  {
    name: "convert___en",
    path: "/convert",
    meta: convert4jgX4a8stLMeta || {},
    component: () => import("/app/pages/(dev)/convert.vue")
  },
  {
    name: "convert___pl",
    path: "/pl/convert",
    meta: convert4jgX4a8stLMeta || {},
    component: () => import("/app/pages/(dev)/convert.vue")
  },
  {
    name: "playground___en",
    path: "/playground",
    meta: playgroundJJ5fXSBmGNMeta || {},
    component: () => import("/app/pages/(dev)/playground.vue")
  },
  {
    name: "playground___pl",
    path: "/pl/playground",
    meta: playgroundJJ5fXSBmGNMeta || {},
    component: () => import("/app/pages/(dev)/playground.vue")
  },
  {
    name: "typography___en",
    path: "/typography",
    meta: typographyLmsZcHpd1AMeta || {},
    component: () => import("/app/pages/(dev)/typography.vue")
  },
  {
    name: "typography___pl",
    path: "/pl/typography",
    meta: typographyLmsZcHpd1AMeta || {},
    component: () => import("/app/pages/(dev)/typography.vue")
  },
  {
    name: "privacy-policy___en",
    path: "/privacy-policy",
    component: () => import("/app/pages/(legal)/privacy-policy.vue")
  },
  {
    name: "privacy-policy___pl",
    path: "/pl/privacy-policy",
    component: () => import("/app/pages/(legal)/privacy-policy.vue")
  },
  {
    name: "terms-and-conditions___en",
    path: "/terms-and-conditions",
    component: () => import("/app/pages/(legal)/terms-and-conditions.vue")
  },
  {
    name: "terms-and-conditions___pl",
    path: "/pl/terms-and-conditions",
    component: () => import("/app/pages/(legal)/terms-and-conditions.vue")
  },
  {
    name: "categories-categorySlug-productSlug___en",
    path: "/categories/:categorySlug()/:productSlug()",
    component: () => import("/app/pages/(products)/categories/[categorySlug]/[productSlug]/index.vue")
  },
  {
    name: "categories-categorySlug-productSlug___pl",
    path: "/pl/categories/:categorySlug()/:productSlug()",
    component: () => import("/app/pages/(products)/categories/[categorySlug]/[productSlug]/index.vue")
  },
  {
    name: "categories-categorySlug___en",
    path: "/categories/:categorySlug()",
    component: () => import("/app/pages/(products)/categories/[categorySlug]/index.vue")
  },
  {
    name: "categories-categorySlug___pl",
    path: "/pl/categories/:categorySlug()",
    component: () => import("/app/pages/(products)/categories/[categorySlug]/index.vue")
  },
  {
    name: "categories___en",
    path: "/categories",
    component: () => import("/app/pages/(products)/categories/index.vue")
  },
  {
    name: "categories___pl",
    path: "/pl/categories",
    component: () => import("/app/pages/(products)/categories/index.vue")
  },
  {
    name: "products-slug___en",
    path: "/products/:slug()",
    component: () => import("/app/pages/(products)/products/[slug]/index.vue")
  },
  {
    name: "products-slug___pl",
    path: "/pl/products/:slug()",
    component: () => import("/app/pages/(products)/products/[slug]/index.vue")
  },
  {
    name: "about___en",
    path: "/about",
    component: () => import("/app/pages/about.vue")
  },
  {
    name: "about___pl",
    path: "/pl/about",
    component: () => import("/app/pages/about.vue")
  },
  {
    name: "cart___en",
    path: "/cart",
    component: () => import("/app/pages/cart/index.vue")
  },
  {
    name: "cart___pl",
    path: "/pl/cart",
    component: () => import("/app/pages/cart/index.vue")
  },
  {
    name: "checkout___en",
    path: "/checkout",
    component: () => import("/app/pages/checkout/index.vue")
  },
  {
    name: "checkout___pl",
    path: "/pl/checkout",
    component: () => import("/app/pages/checkout/index.vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___pl",
    path: "/pl",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "lab___en",
    path: "/lab",
    component: () => import("/app/pages/lab.vue")
  },
  {
    name: "lab___pl",
    path: "/pl/lab",
    component: () => import("/app/pages/lab.vue")
  },
  {
    name: "orders-id___en",
    path: "/orders/:id()",
    component: () => import("/app/pages/orders/[id]/index.vue")
  },
  {
    name: "orders-id___pl",
    path: "/pl/orders/:id()",
    component: () => import("/app/pages/orders/[id]/index.vue")
  },
  {
    name: "profile___en",
    path: "/profile",
    meta: profileupBlgdy3vLMeta || {},
    component: () => import("/app/pages/profile.vue"),
    children: [
  {
    name: "profile-addresses___en",
    path: "addresses",
    component: () => import("/app/pages/profile/addresses.vue")
  },
  {
    name: "profile-gift-cards___en",
    path: "gift-cards",
    component: () => import("/app/pages/profile/gift-cards.vue")
  },
  {
    name: "profile-orders___en",
    path: "orders",
    component: () => import("/app/pages/profile/orders.vue")
  }
]
  },
  {
    name: "profile___pl",
    path: "/pl/profile",
    meta: profileupBlgdy3vLMeta || {},
    component: () => import("/app/pages/profile.vue"),
    children: [
  {
    name: "profile-addresses___pl",
    path: "addresses",
    component: () => import("/app/pages/profile/addresses.vue")
  },
  {
    name: "profile-gift-cards___pl",
    path: "gift-cards",
    component: () => import("/app/pages/profile/gift-cards.vue")
  },
  {
    name: "profile-orders___pl",
    path: "orders",
    component: () => import("/app/pages/profile/orders.vue")
  }
]
  },
  {
    name: "protein-articles-slug___en",
    path: "/protein-articles/:slug()",
    component: () => import("/app/pages/protein-articles/[slug]/index.vue")
  },
  {
    name: "protein-articles-slug___pl",
    path: "/pl/protein-articles/:slug()",
    component: () => import("/app/pages/protein-articles/[slug]/index.vue")
  },
  {
    name: "protein-articles___en",
    path: "/protein-articles",
    component: () => import("/app/pages/protein-articles/index.vue")
  },
  {
    name: "protein-articles___pl",
    path: "/pl/protein-articles",
    component: () => import("/app/pages/protein-articles/index.vue")
  },
  {
    name: "services___en",
    path: "/services",
    component: () => import("/app/pages/services.vue")
  },
  {
    name: "services___pl",
    path: "/pl/services",
    component: () => import("/app/pages/services.vue")
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en-US-sitemap.xml",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/pl-PL-sitemap.xml",
    component: component_45stubnO9MU04yTU
  }
]