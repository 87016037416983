<template>
  <Select
    v-if="options.length"
    v-model="currentLocale"
    :options="options"
    trigger-class="rounded bg-[#2c2d2f] p-2 h-auto hover:bg-[#2c2d2f]"
    value-class="sr-only"
    hide-arrow
    hide-option-check
    @update:model-value="onSetLocale"
  >
    <template #trigger-prefix>
      <Icon :icon="`flag:${channel}-4x3`" class="size-4" />
    </template>
  </Select>
</template>
<script setup lang="ts">
import { Icon } from "@iconify/vue";

import { useChannel } from "~/src/channel/composables";

import type { SelectOption } from "./Select.vue";

const { t, locale: currentLocale, locales, setLocale } = useI18n();
const { channel } = useChannel();

const onSetLocale = (value: string) => {
  setLocale(value);
  refreshNuxtData();
};

const options = computed<SelectOption[]>(() =>
  locales.value.map((locale) => ({
    value: locale.code,
    icon: "flag:" + (locale.channel ?? locale.code) + "-4x3",
    label: t("header.countryWithCurrency", 1, {
      locale: locale.code,
    }),
  }))
);
</script>
