<template>
  <Icon icon="svg-spinners:tadpole" :class="twMerge('h-4 w-4', props.class)" />
</template>
<script setup lang="ts">
import { Icon } from "@iconify/vue";
import { twMerge } from "tailwind-merge";

const props = defineProps<{
  class?: string;
}>();
</script>
