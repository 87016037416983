<template>
  <ToastProvider>
    <TooltipProvider>
      <Header :key="languageCode" :navigation="data?.navigation" />
      <div class="flex min-h-[calc(100dvh-105px)] scroll-pt-[105px] flex-col">
        <main
          v-if="error.statusCode !== 404"
          class="md:mb-18 m-auto mb-6 flex w-full max-w-7xl flex-1 flex-col items-center justify-center gap-6 px-3 py-3 sm:mb-12 md:px-6"
        >
          <h1 v-if="error?.statusCode === 404" class="mt-4 text-center">
            {{ errorMessage }}
          </h1>
          <NuxtLinkLocale href="/" class="as-h3 rounded-lg border-2 border-primary px-3 py-2">
            {{ $t("error.backToHome") }}
          </NuxtLinkLocale>
          {{ error.message }}
        </main>
        <main v-else class="relative flex-1 py-48 pt-24">
          <NuxtImg src="/not-found-decoration.svg" alt="" class="absolute right-0 top-0 h-full" />
          <div class="mx-auto w-full max-w-[520px]">
            <h1 class="text-7xl font-medium text-title-reversed">
              404 <span class="mt-3 block text-4xl">{{ $t("notFound.title") }}</span>
            </h1>
            <p class="py-8 leading-6 text-subtitle">
              {{ $t("notFound.description") }}
            </p>
            <NuxtLinkLocale
              to="/"
              class="flex w-fit justify-center gap-3 rounded bg-eerie-black px-5 py-4 text-center text-sm font-medium text-white transition-all duration-300 hover:bg-[#3a4044]"
            >
              {{ $t("notFound.button") }}
            </NuxtLinkLocale>
          </div>
        </main>
        <Footer :key="languageCode" :footer="data?.footer" />
      </div>

      <ToastViewport
        class="fixed bottom-0 right-0 z-[2147483647] m-0 flex w-[390px] max-w-[100vw] list-none flex-col gap-[10px] p-[var(--viewport-padding)] outline-none [--viewport-padding:_25px]"
      />
    </TooltipProvider>
  </ToastProvider>
</template>
<script setup lang="ts">
import { DefaultLayoutDocument } from "@graphql";
import { ToastProvider, ToastViewport, TooltipProvider } from "radix-vue";

import type { NuxtError } from "#app";

import { useChannel } from "./src/channel/composables";

const { error } = defineProps<{
  error: NuxtError;
}>();

const { t } = useI18n();
const { languageCode } = useChannel();

const { result: data } = useQuery(
  DefaultLayoutDocument,
  computed(() => ({
    languageCode: languageCode.value,
  }))
);

const errorMessage = computed(() => {
  if (error.statusCode === 404) {
    return t("error.404");
  }

  return t("error.somethingWentWrong");
});
</script>
