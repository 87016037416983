<template>
  <div class="max-w-[380px] rounded-bl-lg rounded-br-lg border bg-white">
    <ul v-if="checkout?.lines.length" class="mb-2 max-h-[40vh] overflow-y-auto px-5 pb-0 pt-5">
      <li
        v-for="line in checkout.lines"
        :key="line.id"
        class="flex justify-between gap-16 py-3 [&:not(:last-child)]:border-b"
      >
        <div>
          <p class="line-clamp-2 text-lg font-medium text-title-reversed">
            {{ line.variant.product.name }}
          </p>
          <span class="text-subtitle">
            {{ line.variant.product.slug }}
          </span>
        </div>
        <div class="flex flex-col items-end justify-between">
          <span class="text-subtitle">{{ formatCurrency(line.totalPrice.gross) }}</span>
          <button
            class="-mr-2 rounded-lg p-3 text-error transition-all hover:bg-error/10"
            @click="$emit('delete-line', line.id)"
          >
            <Icon icon="lucide:trash-2" />
          </button>
        </div>
      </li>
    </ul>
    <!-- <Separator /> -->
    <div v-else class="py-6">
      <p class="text-center text-title-reversed">
        {{ $t("order.cart.empty") }}
      </p>
    </div>
    <div v-if="checkout?.lines.length" class="sticky bottom-0 border-t bg-white p-5">
      <div class="flex justify-between">
        <p>
          <span class="font-medium">{{ $t("order.subtotal") }}</span>
          <span class="text-subtitle"> (excl. taxes) </span>
        </p>
        <div class="flex flex-col items-end">
          <span class="text-subtitle">
            {{ formatCurrency(checkout.totalPrice.gross) }}
          </span>
        </div>
      </div>
      <NuxtLinkLocale
        :href="`/checkout?checkoutId=${checkout?.id}`"
        class="mt-6 flex justify-center gap-3 rounded bg-eerie-black px-5 py-4 text-center text-sm font-medium text-white transition-all duration-300 hover:bg-[#3a4044]"
        disabled
      >
        {{ $t("order.goToCheckout") }}
      </NuxtLinkLocale>
    </div>
  </div>
</template>
<script lang="ts" setup>
import type { CartPreviewQuery } from "@graphql";
import { Icon } from "@iconify/vue";

export type checkoutItem = {
  id: string;
  title: string;
  subtitle?: string;
  total: {
    currency: string;
    amount: number;
  };
};

const props = defineProps<{
  checkout: CartPreviewQuery["checkout"];
}>();

const checkout = computed(() => props.checkout);

defineEmits<{
  "delete-line": [id: string];
}>();

const { formatCurrency } = useFormatCurrency();
</script>
