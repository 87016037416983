<template>
  <NavigationMenuRoot v-model="currentTrigger">
    <NavigationMenuList class="relative flex gap-5">
      <NavigationMenuItem v-for="item in navigation?.items" :key="item.name" :value="item.id">
        <NavigationMenuTrigger
          :as-child="!item?.children?.length"
          class="flex rounded px-2 py-1 font-custom-mono-space text-sm font-[600] text-subtitle-dark transition-colors hover:bg-white/10 data-[state=open]:bg-white/10"
        >
          <template v-if="item?.children?.length">
            {{ item.translation?.name ?? item.name }}
          </template>
          <NuxtLinkLocale v-else :href="getRelativeUrl(item.url)">
            {{ item.translation?.name ?? item.name }}
          </NuxtLinkLocale>
        </NavigationMenuTrigger>
        <NavigationMenuContent
          v-if="item?.children?.length"
          class="absolute top-[calc(100%+15px)] data-[motion=from-end]:animate-enterFromRight data-[motion=from-start]:animate-enterFromLeft data-[motion=to-end]:animate-exitToRight data-[motion=to-start]:animate-exitToLeft"
        >
          <NavigationMenuSub v-model="currentSubTrigger" class="flex w-full bg-white shadow-md">
            <NavigationMenuList class="flex h-full flex-col items-start gap-2 p-8">
              <NavigationMenuItem v-for="subitem in item?.children" :key="subitem.name">
                <NavigationMenuTrigger
                  class="rounded border border-stroke px-2 py-1 font-custom-mono-space font-[600] text-title-reversed transition-colors hover:border-eerie-black hover:bg-eerie-black hover:text-white data-[state=open]:border-eerie-black data-[state=open]:bg-eerie-black data-[state=open]:text-white"
                >
                  <NuxtLinkLocale
                    :href="
                      subitem.url
                        ? getRelativeUrl(subitem.url)
                        : subitem.category?.slug && `/categories/${subitem.category.slug}`
                    "
                  >
                    {{ subitem.translation?.name ?? subitem.name }}
                  </NuxtLinkLocale>
                </NavigationMenuTrigger>
                <NavigationMenuContent class="h-full">
                  <ul class="grid h-full min-h-full auto-cols-min grid-flow-col">
                    <li
                      v-for="subL2item in subitem?.children"
                      :key="subL2item.name"
                      class="h-full min-w-[364px] border-l border-stroke p-8"
                    >
                      <div class="w-fit rounded bg-surface-2 p-2">
                        <Icon icon="akar-icons:sparkles" class="size-4 text-icon-fill-light" />
                      </div>
                      <NuxtLinkLocale
                        :href="
                          (subL2item.category && `/categories/${subL2item.category.slug}`) ??
                          undefined
                        "
                        class="mt-3 flex text-xl font-medium text-title-reversed"
                      >
                        {{ subL2item.translation?.name ?? subL2item.name }}
                      </NuxtLinkLocale>
                      <div class="my-4 h-px bg-stroke" />
                      <ul class="flex flex-col gap-2">
                        <li
                          v-for="subL3item in subL2item?.children"
                          :key="subL3item.name"
                          class="rounded p-2 font-medium text-subtitle transition-colors hover:bg-stroke hover:text-title-reversed/90"
                        >
                          <NuxtLinkLocale
                            :href="
                              (subL3item.category && `/categories/${subL3item.category.slug}`) ??
                              undefined
                            "
                          >
                            {{ subL3item.translation?.name ?? subL3item.name }}
                          </NuxtLinkLocale>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </NavigationMenuContent>
              </NavigationMenuItem>
            </NavigationMenuList>
            <NavigationMenuViewport />
          </NavigationMenuSub>
        </NavigationMenuContent>
      </NavigationMenuItem>
    </NavigationMenuList>
    <!--    <div-->
    <!--      v-if="currentTriggerValue?.children?.length"-->
    <!--      class="absolute left-0 top-full flex w-full justify-center perspective-[2000px]"-->
    <!--    >-->
    <!--      <NavigationMenuViewport-->
    <!--        class="relative top-px h-[var(&#45;&#45;radix-navigation-menu-viewport-height)] w-screen origin-[top_center] overflow-hidden rounded-md bg-transparent drop-shadow-sm transition-[width,_height] duration-300 data-[state=closed]:animate-scaleOut data-[state=open]:animate-scaleIn sm:w-[var(&#45;&#45;radix-navigation-menu-viewport-width)]"-->
    <!--      />-->
    <!--    </div>-->
  </NavigationMenuRoot>
</template>

<script setup lang="ts">
import { Icon } from "@iconify/vue";
import {
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuRoot,
  NavigationMenuSub,
  NavigationMenuTrigger,
  NavigationMenuViewport,
} from "radix-vue";

import type { DefaultLayoutQuery } from "~/src/api/generated/graphql";

export type Navigation = DefaultLayoutQuery["navigation"];

const { navigation } = defineProps<{
  navigation: Navigation;
}>();

const currentSubTrigger = ref("");
const currentTrigger = ref("");
</script>
<script lang="ts">
export const getRelativeUrl = (url: string | null | undefined = ""): string | undefined => {
  if (!url) return;

  return new URL(url).pathname;
};
</script>
