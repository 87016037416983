<template>
  <ComboboxRoot :value="search" @update:value="onValueUpdate">
    <ComboboxAnchor>
      <ComboboxInput
        :class="
          twMerge(
            'block w-full rounded border border-[#2c2d2f] bg-[#2c2d2f] p-2 pl-4 text-sm text-title outline-none placeholder:text-title focus:border-uniform',
            inputClass
          )
        "
        :placeholder="$t('common.search')"
        :value="search"
        @input="onSearch"
      />
    </ComboboxAnchor>
    <ComboboxContent
      v-if="hasInput"
      class="z-10 w-[var(--radix-combobox-trigger-width)] space-y-1 rounded-md bg-white p-3 shadow"
      :side-offset="4"
      position="popper"
    >
      <ComboboxItem
        v-for="result in data"
        :key="result.id"
        :value="result.slug"
        class="group !flex items-center justify-between gap-2 rounded p-2 font-medium leading-5 text-subtitle transition-colors hover:bg-[#efefef] hover:text-title-reversed"
        as-child
      >
        <NuxtLinkLocale
          :to="`/categories/${result.slug}?search=${search}`"
          @click="handleResultClick"
        >
          <div class="flex w-full items-center justify-between">
            <span>{{ result.name }}</span>
            <span
              class="flex h-5 min-w-5 items-center justify-center rounded bg-[#efefef] px-0.5 text-sm font-[600] leading-[18px] text-subtitle transition-colors group-hover:bg-white"
            >
              <template v-if="!loading">{{ result.productsCount }}</template>
              <Spinner v-else class="size-3" />
            </span>
          </div>
        </NuxtLinkLocale>
      </ComboboxItem>
    </ComboboxContent>
  </ComboboxRoot>
</template>

<script setup lang="ts">
import {
  ComboboxAnchor,
  ComboboxContent,
  ComboboxInput,
  ComboboxItem,
  ComboboxRoot,
} from "radix-vue";
import { twMerge } from "tailwind-merge";
import { useRoute } from "vue-router";

import { useGlobalSearch } from "~/src/search/composables/useGlobalSearch";
import { searchStore } from "~/stores/search";
import { Breakpoints } from "~/utils/mobile";

const searchParams = useUrlSearchParams<{ search?: string }>();
const { search: _search, data, debouncedSearch, loading } = useGlobalSearch();
const focused = toRef(searchStore, "open");
const mobileOpen = toRef(searchStore, "mobileOpen");
const search = toRef(searchStore, "search");

// Watch for route changes to handle clearing search value after navigation
watch(
  useRoute,
  (_route) => {
    if (searchStore.navigatingFromSearch) {
      // Clear search when navigating from search results
      search.value = "";
      searchParams.search = "";
      searchStore.navigatingFromSearch = false;
    }
  },
  { immediate: true, deep: true }
);

// Remove URL param sync watchers since we only want to update search on user interaction
const hasInput = computed(() => Boolean(search.value.length));

const onSearch = (e: Event | string) => {
  const input = typeof e === "string" ? e : (e.target as HTMLInputElement).value;
  if (search.value !== input) {
    search.value = input;
    searchParams.search = input;
    debouncedSearch(input);
  }
};

const onValueUpdate = (value: string) => {
  // Prevent the combobox from updating the value when selecting an item
  if (value !== search.value) {
    search.value = value;
    searchParams.search = value;
    debouncedSearch(value);
  }
};

const handleResultClick = () => {
  focused.value = false;
  // Set a flag to indicate we're navigating from search result
  searchStore.navigatingFromSearch = true;
};

if (typeof document !== "undefined") {
  useResizeObserver(document.body, (entries) => {
    if (entries[0].contentRect.width < Breakpoints.sm && focused.value) {
      focused.value = false;
      mobileOpen.value = true;
    }
  });
}

// Only perform search if we have data to search for, but don't update the input value
onMounted(() => {
  if (searchParams.search?.length && !data.value?.length) {
    _search(searchParams.search);
  }
});

defineProps<{
  inputClass?: string;
}>();
</script>
