<template>
  <header
    class="sticky top-0 z-20 flex min-h-[57px] items-center justify-between border-b border-[#333] bg-surface-0 px-3 py-2"
  >
    <button
      class="rounded bg-[#2c2d2f] p-2 lg:hidden"
      :aria-label="$t('common.search')"
      @click="isMobileSearchOpen = !isMobileSearchOpen"
    >
      <Icon icon="akar-icons:search" class="size-4 text-icon-fill-light" />
    </button>
    <MainMenu :navigation="navigation" class="hidden lg:block" />
    <NuxtLinkLocale href="/" class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
      <NuxtImg src="/logo.svg" alt="Home" class="h-[40px]" />
    </NuxtLinkLocale>
    <div class="flex items-center gap-2">
      <GlobalSearch class="hidden lg:block" />
      <LanguageSelect class="hidden lg:block" />
      <NuxtLinkLocale to="/profile" class="hidden rounded bg-[#2c2d2f] p-2 lg:block">
        <Icon icon="akar-icons:person" class="size-4 text-icon-fill-light" :ssr="true" />
        <span class="sr-only">{{ $t("header.myAccount") }} </span>
      </NuxtLinkLocale>
      <CartOverlay>
        <template #trigger="{ checkoutId, itemsCount }">
          <NuxtLinkLocale
            :to="`/cart?checkoutId=${checkoutId}`"
            class="relative rounded bg-[#2c2d2f] p-2"
          >
            <Icon icon="akar-icons:cart" class="size-4 text-icon-fill-light" />
            <span
              v-if="itemsCount"
              class="absolute -right-1 -top-1 flex size-[14px] items-center justify-center rounded-sm bg-[#e3f4ee] font-custom-mono-space text-xs font-[600] text-[#2d5044]"
            >
              {{ itemsCount }}
            </span>
            <span class="sr-only">{{ $t("header.cart") }}</span>
          </NuxtLinkLocale>
        </template>
      </CartOverlay>
      <button
        class="rounded bg-[#2c2d2f] p-2 lg:hidden"
        :aria-label="$t('header.menu')"
        @click="isMobileMenuOpen = !isMobileMenuOpen"
      >
        <Icon icon="akar-icons:three-line-horizontal" class="size-4 text-icon-fill-light" />
      </button>
    </div>
    <MobileNavigationOverlay v-model:open="isMobileMenuOpen" :navigation="navigation" />
    <MobileSearchOverlay v-model:open="isMobileSearchOpen" />
  </header>
</template>

<script setup lang="ts">
import type { DefaultLayoutQuery } from "@graphql";
import { Icon } from "@iconify/vue";

import { CartOverlay } from "~/src/cart/components";

export type Navigation = DefaultLayoutQuery["navigation"];

const { navigation } = defineProps<{
  navigation: Navigation;
}>();

const isMobileMenuOpen = ref(false);
const isMobileSearchOpen = ref(false);
</script>
