<template>
  <MobileOverlayRoot v-model:open="mobileOpen">
    <search class="p-3">
      <form>
        <input
          id="search"
          :value="searchParams.search"
          :aria-label="$t('common.search')"
          :placeholder="$t('common.search')"
          class="block w-full rounded border border-[#2c2d2f] bg-[#2c2d2f] p-2 pl-4 text-sm text-title outline-none placeholder:text-title focus:border-uniform"
          required
          @input="onSearch"
        />
      </form>
      <template v-if="data?.length">
        <ul class="mt-4 space-y-1">
          <li
            v-for="result in data"
            :key="result.id"
            class="group !flex items-center justify-between gap-2 rounded bg-[#efefef] p-2 font-medium leading-5 text-title-reversed transition-colors"
          >
            <NuxtLinkLocale
              :to="`/categories/${result.slug}?search=${searchParams.search}`"
              class="w-full"
              @click="mobileOpen = false"
            >
              <div class="flex w-full items-center justify-between">
                <span>{{ result.name }}</span>
                <span
                  class="flex h-5 min-w-5 items-center justify-center rounded bg-white px-0.5 text-sm font-[600] leading-[18px] text-subtitle transition-colors"
                >
                  <template v-if="!loading">{{ result.productsCount }}</template>
                  <Spinner v-else class="size-3" />
                </span>
              </div>
            </NuxtLinkLocale>
          </li>
        </ul>
      </template>
    </search>
  </MobileOverlayRoot>
</template>
<script setup lang="ts">
import { useGlobalSearch } from "~/src/search/composables/useGlobalSearch";
import { searchStore } from "~/stores/search";
import { Breakpoints } from "~/utils/mobile";

import MobileOverlayRoot from "./MobileOverlayRoot.vue";

const searchParams = useUrlSearchParams<{ search?: string }>();

const mobileOpen = toRef(searchStore, "mobileOpen");
const open = toRef(searchStore, "open");
const search = toRef(searchStore, "search");
const searchParamsSearch = toRef(searchParams, "search");

syncRef(search, searchParamsSearch, {
  transform: {
    rtl: (right) => right || "",
    ltr: (left) => left || undefined,
  },
});

watch(mobileOpen, (open) => {
  if (open) {
    onSearch(search.value);
  }
});

if (typeof document !== "undefined") {
  useResizeObserver(document.body, (entries) => {
    if (entries[0].contentRect.width >= Breakpoints.sm && mobileOpen.value) {
      mobileOpen.value = false;
      open.value = true;
    }
  });
}

const { search: _search, data, debouncedSearch, loading } = useGlobalSearch();

const onSearch = (e: Event | string) => {
  const input = typeof e === "string" ? e : (e.target as HTMLInputElement).value;
  search.value = input;
  debouncedSearch(input);
};

onMounted(() => {
  // NOTE: workaround when user navigates with search params
  if (searchParams.search?.length && !data.value?.length) {
    _search(searchParams.search);
  }
});
</script>
