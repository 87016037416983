<template>
  <Teleport to="body">
    <Transition name="slide">
      <div
        v-if="open"
        v-bind="$attrs"
        ref="mobile-nav"
        class="fixed bottom-0 right-0 top-0 z-30 h-dvh w-full max-w-[min(95vw,280px)] bg-surface-0 text-white"
        @touchmove.prevent
      >
        <div class="flex min-h-[57px] items-center justify-between">
          <button class="rounded p-3 hover:bg-black/10" @click="open = false">
            <Icon icon="radix-icons:cross-2" class="size-6" />
          </button>
          <div class="p-3">
            <LanguageSelect />
          </div>
        </div>
        <slot />
      </div>
    </Transition>
  </Teleport>
</template>
<script setup lang="ts">
import { Icon } from "@iconify/vue";

defineSlots<{
  default(): unknown;
}>();
const open = defineModel<boolean>("open", { default: false });

const target = templateRef("mobile-nav");
onClickOutside(target, () => (open.value = false));
</script>
<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: all 250ms cubic-bezier(0.16, 1, 0.3, 1);
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
}
</style>
