<template>
  <MobileOverlayRoot v-model:open="open">
    <div class="flex h-full max-h-[93%] flex-col justify-between">
      <nav>
        <ul class="flex flex-col font-custom-mono-space">
          <li
            v-for="item in navigation?.items"
            :key="item.name"
            :value="item.id"
            class="text-xl font-[600]"
          >
            <NuxtLinkLocale
              v-if="!item.children?.length"
              :href="getRelativeUrl(item.url)"
              class="inline-block w-full px-6 py-3"
              @click="open = false"
            >
              {{ item.translation?.name ?? item.name }}
            </NuxtLinkLocale>
            <div v-else class="py-3">
              <p class="w-full px-6 text-start">
                {{ item.translation?.name ?? item.name }}
                <NuxtImg src="/icons/chevron-down.svg" class="inline" />
              </p>
              <ul class="ml-6 mt-3 text-base font-normal">
                <li v-for="subitem in item.children" :key="subitem.id">
                  <NuxtLinkLocale
                    :href="`/categories/${subitem.category?.slug}`"
                    @click="open = false"
                  >
                    {{ subitem.translation?.name ?? subitem.name }}
                  </NuxtLinkLocale>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </nav>
      <div class="m-3 mt-auto flex flex-col gap-3">
        <NuxtLinkLocale
          v-if="isAuthenticated"
          href="/profile"
          class="flex justify-center gap-3 rounded border border-stroke bg-white px-[19px] py-[15px] text-center text-sm font-medium text-title-reversed underline decoration-transparent hover:decoration-title-reversed"
          @click="open = false"
        >
          {{ $t("header.myAccount") }}
        </NuxtLinkLocale>
        <button
          v-if="isAuthenticated"
          class="flex justify-center gap-3 rounded border border-white px-[19px] py-[15px] text-center text-sm font-medium text-white"
          @click="() => signOut().then(() => (open = false))"
        >
          {{ $t("header.signOut") }}
        </button>
        <NuxtLinkLocale
          v-else
          href="/login"
          class="flex justify-center gap-3 rounded border border-stroke bg-white px-[19px] py-[15px] text-center text-sm font-medium text-title-reversed underline decoration-transparent hover:decoration-title-reversed"
          @click="open = false"
        >
          {{ $t("auth.login") }}
        </NuxtLinkLocale>
      </div>
    </div>
  </MobileOverlayRoot>
</template>
<script setup lang="ts">
import { useSignOut } from "~/src/auth/composables";

import type { Navigation } from "./MainMenu.vue";
import { getRelativeUrl } from "./MainMenu.vue";
import MobileOverlayRoot from "./MobileOverlayRoot.vue";

const open = defineModel<boolean>("open", { default: false });

const { navigation } = defineProps<{
  navigation: Navigation;
}>();

const { signOut, isAuthenticated } = useSignOut();
</script>
