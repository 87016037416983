import { DEFAULT_TOAST_DURATION } from "~/constants";

export type Toast = {
  id: number;
  title?: string;
  description?: string;
  duration?: number;
  type: "success" | "error" | "info" | "warning";
  open?: MaybeRefOrGetter<boolean>;
  action?: {
    buttonText: string;
    altText: string;
    onClick: () => void;
  };
};

export type ToastBase = Omit<Toast, "type" | "id"> | string;

type ToastStore = {
  toasts: Toast[];
  success: (toast: ToastBase) => void;
  error: (toast: ToastBase) => void;
  info: (toast: ToastBase) => void;
  warning: (toast: ToastBase) => void;
  addToast: (toast: ToastBase, type: Toast["type"]) => void;
  removeToast: (id: number) => void;
};

export const toastStore = reactive<ToastStore>({
  toasts: [],

  addToast(toast: ToastBase, type: Toast["type"]) {
    const toastBase: Toast = {
      ...(typeof toast === "string" ? { title: toast } : toast),
      id: Date.now(),
      type,
    };

    this.toasts.push(toastBase);

    setTimeout(() => {
      this.removeToast(toastBase.id);
    }, toastBase.duration ?? DEFAULT_TOAST_DURATION);
  },
  success(toast) {
    this.addToast(toast, "success");
  },
  error(toast) {
    this.addToast(toast, "error");
  },
  warning(toast) {
    this.addToast(toast, "warning");
  },
  info(toast) {
    this.addToast(toast, "info");
  },
  removeToast(id: number) {
    const index = this.toasts.findIndex((t) => t.id === id);
    if (index !== -1) {
      this.toasts.splice(index, 1);
    }
  },
});
