<template>
  <SelectRoot v-model="value">
    <SelectTrigger
      :class="
        twMerge(
          'inline-flex h-[35px] items-center justify-between gap-[5px] rounded bg-white px-[15px] text-[13px] leading-none text-text-main outline-none hover:bg-gray1 focus:shadow-black focus:ring-2 focus:ring-focus',
          triggerClass
        )
      "
      aria-label="Customize options"
      v-bind="$attrs"
    >
      <slot name="trigger-prefix" />
      <SelectValue :placeholder="placeholder" :class="twMerge('text-nowrap', valueClass)" />
      <NuxtImg
        v-if="!props.hideArrow"
        src="/icons/chevron-down.svg"
        :alt="$t('common.open')"
        class="inline"
      />
    </SelectTrigger>

    <SelectPortal>
      <SelectContent
        class="z-[100] min-w-[var(--radix-select-trigger-width)] rounded border-stroke bg-white drop-shadow-md will-change-[opacity,transform] data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade data-[side=right]:animate-slideLeftAndFade data-[side=top]:animate-slideDownAndFade"
        position="popper"
        :side-offset="4"
        side="bottom"
      >
        <SelectViewport class="p-[5px]">
          <SelectLabel v-if="label" class="px-[25px] text-xs leading-[25px] text-gray5">
            {{ label }}
          </SelectLabel>
          <SelectGroup>
            <SelectItem
              v-for="option in options"
              :key="option.value"
              :class="
                twMerge(
                  'relative flex h-[25px] select-none items-center rounded-[3px] pr-[35px] text-[13px] leading-none focus:bg-text-main focus:text-primary-lightest focus:outline-none data-[disabled]:pointer-events-none data-[highlighted]:bg-text-main data-[disabled]:text-gray4 data-[highlighted]:text-primary-lightest data-[highlighted]:outline-none',
                  !props.hideOptionCheck ? 'pl-[25px]' : 'pl-[4px]'
                )
              "
              :value="option.value"
              :disabled="option.disabled"
            >
              <SelectItemIndicator
                v-if="!props.hideOptionCheck"
                class="absolute left-0 inline-flex w-[25px] items-center justify-center"
              >
                <Icon icon="radix-icons:check" />
              </SelectItemIndicator>

              <SelectItemText class="flex items-center gap-2">
                <Icon v-if="option.icon" :icon="option.icon" />
                {{ option?.label ?? option.value }}
              </SelectItemText>
            </SelectItem>
          </SelectGroup>
        </SelectViewport>
      </SelectContent>
    </SelectPortal>
  </SelectRoot>
</template>

<script setup lang="ts">
import { Icon } from "@iconify/vue";
import type { SelectRootProps } from "radix-vue";
import {
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectLabel,
  SelectPortal,
  SelectRoot,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from "radix-vue";
import { twMerge } from "tailwind-merge";
import { useField } from "vee-validate";

export type SelectOption = { value: string; label?: string; disabled?: boolean; icon?: string };

export type SelectProps = Pick<SelectRootProps, "disabled" | "defaultValue"> & {
  placeholder?: string;
  label?: string;
  options: SelectOption[];
  triggerClass?: string;
  id?: string;
  name?: string;
  valueClass?: string;
  hideArrow?: boolean;
  hideOptionCheck?: boolean;
};

defineSlots<{
  "trigger-prefix"(): unknown;
}>();
const props = defineProps<SelectProps>();

const fieldId = useId();

const { value } = useField<string>(() => props.id ?? props.name ?? fieldId!, undefined, {
  syncVModel: true,
});
</script>
