<template>
  <footer class="w-full bg-surface-0 text-sm">
    <nav class="m-auto my-16 max-w-[1064px] px-3 md:my-24">
      <ul class="grid grid-cols-2 gap-x-5 gap-y-10 xs:grid-cols-3 md:grid-cols-5">
        <li v-for="(item, index) in items" :key="item.label">
          <div class="flex items-center gap-2">
            <NuxtImg :src="'/icons/footer-' + (index + 1) + '.svg'" alt="" class="size-6" />
            <p class="font-custom-mono-space font-[600] text-title/60">
              {{ item.label }}
            </p>
          </div>
          <ul
            class="relative mt-8 flex w-full flex-col items-start gap-6 pl-[33px] before:absolute before:left-0 before:h-full before:w-px before:bg-[#303030]"
          >
            <li
              v-for="subitem in item.subcategories"
              :key="subitem.label"
              class="font-medium text-title/90"
            >
              <NuxtLinkLocale :href="subitem.link">
                {{ subitem.label }}
              </NuxtLinkLocale>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
    <div class="h-px bg-[#303030]" />
    <div
      class="m-auto flex max-w-[1064px] flex-col items-center justify-between gap-4 px-3 py-6 md:flex-row"
    >
      <div class="flex items-center gap-3 font-medium text-title/90">
        <NuxtLinkLocale href="/terms-and-conditions">
          {{ $t("footer.termsAndConditions") }}
        </NuxtLinkLocale>
        <div class="size-[6.6px] rotate-45 rounded-[1.6px] bg-title/20" />
        <NuxtLinkLocale href="/privacy-policy">
          {{ $t("footer.privacyPolicy") }}
        </NuxtLinkLocale>
      </div>
      <div class="flex items-center gap-6">
        <p class="font-custom-mono-space font-[600] text-title/60">
          All rights reserved. 2025 Genotic.
        </p>
        <ul class="flex gap-1">
          <li>
            <a
              href="https://x.com/GenoticInc"
              target="_blank"
              class="block rounded bg-icon-surface p-2"
            >
              <Icon icon="ri:twitter-x-fill" height="none" class="size-4 text-icon-fill-light" />
              <span class="sr-only">X</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/genotic/"
              target="_blank"
              class="block rounded bg-icon-surface p-2"
            >
              <Icon icon="mdi:linkedin" height="none" class="size-4 text-icon-fill-light" />
              <span class="sr-only">LinkedIn</span>
            </a>
          </li>
          <li>
            <a href="#" class="block rounded bg-icon-surface p-2">
              <Icon icon="mdi:facebook" height="none" class="size-4 text-icon-fill-light" />
              <span class="sr-only">FaceBook</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>
<script setup lang="ts">
import type { DefaultLayoutQuery } from "@graphql";
import { Icon } from "@iconify/vue";

const { footer } = defineProps<{
  footer: DefaultLayoutQuery["footer"];
}>();

type Item = {
  label: string;
  link?: string;
  subcategories?: { label: string; link?: string; isNew?: boolean; hiring?: boolean }[];
};

const items = computed<Item[]>(
  () =>
    footer?.items?.map((item) => ({
      label: item.translation?.name ?? item.name,
      link: item.url ? getRelativeUrl(item.url) : undefined,
      subcategories: item.children?.map((subItem) => {
        return {
          label: subItem.translation?.name ?? subItem.name,
          link: subItem.url
            ? getRelativeUrl(subItem.url)
            : subItem.category
              ? `/categories/${subItem.category.slug}`
              : undefined,
        };
      }),
    })) ?? []
);
</script>

<script lang="ts">
export const getRelativeUrl = (url: string | null | undefined = ""): string | undefined => {
  if (!url) return;

  return new URL(url).pathname;
};
</script>
